import axios from "axios"
import Functions from "@/utils/functions"
import Vue from "vue"

const api_wh = axios.create({
  baseURL: process.env.VUE_APP_API_WANNAHOST,
})
api_wh.interceptors.request.use(
  function (config) {
    const access = localStorage.getItem("wh_access_token")
    const device_id = localStorage.getItem("wh_device_uid")
    if (access) {
      const base64 = access.split(".")[1].replace(/-/g, "+").replace(/_/g, "/")
      const tokenExpire = JSON.parse(Buffer.from(base64, "base64").toString("utf-8")).exp
      if (tokenExpire * 1000 >= Date.now()) {
        config.headers.Authorization = `Bearer ${access}`
        config.headers["x-att-deviceid"] = device_id
      } else {
        localStorage.removeItem("wh_access_token")
        localStorage.removeItem("wh_refresh_token")
      }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
api_wh.interceptors.response.use(
  (response) => {
    return response
  },
  (error, response) => {
    let message_text =
      "We can't finish your request properly, please contact us and we will solve your problem"
    try {
      const status = error.response ? error.response.status : null
      const customErrors = [423]
      message_text = Functions.getFirstServerErrorText(error, message_text)
      if (status >= 400 && status < 500 && !customErrors.includes(status)) {
        Vue.alert({
          message: Vue.prototype.$gettext(message_text),
          type: "error",
          timer: 6000,
        })
      }
    } catch (e) {
      console.log(e)
    }
    return Promise.reject(error)
  }
)
export default api_wh
