<template>
  <section>
    <aside class="order-cart">
      <section class="order-cart-wrapper">
        <header v-if="cartItems.length > 0" class="order-cart-header">
          <span class="order-cart-header--title">{{ "My order" }}</span>
          <div class="order-cart-top">
            <div class="order-cart-top--clear" @click="clearList">
              {{ $t("Clear list") }} ({{ cartItems.length }})
            </div>
          </div>
        </header>
        <section v-if="cartItems.length > 0 && !isLoading" class="order-cart-content">
          <OrderCartItem
            v-for="cartItem in cartItems"
            :key="cartItem.uid"
            :cart-item="cartItem"
            @delete="$emit('deleteCartItem', cartItem)"
          ></OrderCartItem>
          <div class="container">
            <a
              v-if="cartItems.length > 20 && !expanded"
              href="#"
              class="clickable-text"
              @click="$emit('expandToggle')"
              >Show all</a
            >
            <a
              v-else-if="cartItems.length > 20 && expanded"
              href="#"
              class="clickable-text"
              @click="$emit('expandToggle')"
              >Collapse</a
            >
          </div>
        </section>
        <section v-if="cartItems.length === 0 && !isLoading" class="order-cart-empty">
          <header class="order-cart-header empty">
            <span class="order-cart-header--title">{{ "Your order is empty" }}</span>
          </header>
          <section class="order-cart-empty--body">
            <div class="order-cart-empty--icon">
              <svgicon name="add-queue"></svgicon>
            </div>
          </section>
          <app-button :size="'small'" @click="toMarketplace">{{
            $t("Open marketplace")
          }}</app-button>
        </section>
        <section v-if="isLoading" class="order-cart-loading">
          <loading class="content-loader"></loading>
        </section>
      </section>
      <section class="order-cart-footer">
        <ul class="order-cart-footer--summary">
          <li class="order-cart-footer--summary--item">
            <div class="order-cart-footer--summary--item--name">
              {{ "Total" }}
            </div>
            <div class="order-cart-footer--summary--item--value">{{ total | price }}</div>
          </li>
          <li class="order-cart-footer--summary--item">
            <div class="order-cart-footer--summary--item--name">
              {{ "Total VAT" }}
            </div>
            <div class="order-cart-footer--summary--item--value">{{ totalVat | price }}</div>
          </li>
        </ul>
        <div class="order-cart-footer--wrapper">
          <app-button
            v-if="cartItems.length"
            :loading="isLoading"
            variant="primary"
            size="normal"
            @click="onSubmit"
          >
            {{ $gettext("Make order") }}
          </app-button>
        </div>
      </section>
    </aside>
  </section>
</template>

<script>
import withCurrentOrganization from "@/mixins/withCurrentOrganization"
import AppButton from "@/components/ui/AppButton.vue"
import Loading from "@/components/shared/Loading.vue"
import OrderCartItem from "@/components/shared/OrderCart/OrderCartItem.vue"
import AppModal from "@/components/ui/AppModal.vue"

export default {
  components: { OrderCartItem, Loading, AppButton },
  mixins: [withCurrentOrganization],
  props: {
    cartItems: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    total: {
      type: Number,
      required: true,
    },
    totalVat: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: false,
    },
    allAvail: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },

  methods: {
    toMarketplace() {
      this.$emit("toMarketplace")
    },
    async deleteItem(item) {
      this.$emit("deleteItem", item.network)
    },
    async clearList() {
      await this.$store.dispatch("wh_cart/clearCart")
    },
    async onSubmit() {
      console.log("submit")
      this.$emit("submit")
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";

.order-cart {
  font-family: var(--font-secondary);
  letter-spacing: 0.4px;
  background: var(--text-color);
  height: calc(100vh - 102px);
  //width: $--sidebar-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d9dff63b;

    &--clear {
      color: var(--icons-color);
      border-bottom: 1px dashed;
      cursor: pointer;
      letter-spacing: 0.5px;
    }
  }
}

.order-cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--sidebar-color);
  padding: 15px 12px;

  &--icon {
    font-size: 24px;
    color: var(--icons-color);
  }

  &--title {
    font-size: 16px;
    text-transform: uppercase;
  }

  &--clear {
    background: none;
    border: none;
  }

  &.empty {
    justify-content: center;
  }
}

.order-cart-content {
  max-height: calc(100vh - 305px);
  overflow: auto;
  min-height: 200px;
}

.order-cart-footer {
  border-top: 1px solid #d9dff63b;
  color: #fff;

  &--wrapper {
    padding: 15px 10px 0px 10px;
    display: flex;
    justify-content: center;

    button {
      margin-bottom: 10px;
    }
  }

  &--summary {
    list-style: none;

    &--item {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #d9dff63b;
      text-transform: uppercase;

      &--name {
        color: var(--icons-color);
      }
    }
  }
}

.order-cart-empty {
  position: relative;
  top: 25%;
  padding: 40px 20px;
  text-align: center;

  &--icon {
    font-size: 80px;
    color: var(--icons-color);
    margin: 30px 0;
  }
}

.order-cart-loading {
  transform: translateX(20%);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable-text {
  text-decoration: none;
  color: white; /* Set your desired text color */
  border-bottom: 2px solid #000; /* Set your desired border color and thickness */
  cursor: pointer;
  transition: border-bottom 0.3s ease; /* Optional: Add a smooth transition effect */
}

.clickable-text:hover {
  border-bottom: 2px solid white; /* Set a different color on hover if desired */
}
</style>
