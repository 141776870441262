<template>
  <div>
    <top-loading></top-loading>
    <div class="application height-100p">
      <div class="application__inner">
        <div class="container-fluid application__container height-100p">
          <div class="row flex-nowrap height-100p">
            <div class="sidebar-outer" :class="[isSidebarOpened && 'sidebar-outer--active']">
              <app-sidebar />
            </div>
            <div class="content-outer">
              <app-header :is-sidebar-opened="isSidebarOpened" @toggle:sidebar="onSidebarToggle" />
              <get-data :fetch-function="getOrganizations">
                <div class="content-stretch">
                  <main :key="key" class="container content">
                    <div class="row">
                      <div class="col-xl-10 col-12">
                        <alert-container />
                      </div>
                    </div>
                    <router-view @rentBlocks="submitRent" />
                  </main>
                  <app-copy />
                </div>
              </get-data>
              <div v-if="showSubstrate" class="substrate" @click="closeSubstrate"></div>
            </div>
            <div
              class="sidebar-outer sidebar-outer--right"
              :class="[rentListOpened && 'sidebar-outer--right--active']"
            >
              <div class="sidebar-outer--wrapper">
                <app-tabs v-model="activeTab" :tabs="tabs" class="sidebar-outer--header"></app-tabs>
                <get-data
                  v-if="activeTab === 'networks'"
                  :fetch-function="getOrders"
                  :infinity="true"
                  :data-preloader-props="{ isSpinner: true }"
                >
                  <rent-list
                    :is-loading="rentLoading"
                    :orders="handleOrders"
                    :total="total.total || 0"
                    :total-vat="total.total_vat || 0"
                    :total-items="orders.count || 0"
                    :expanded="expanded"
                    :all-avail="isAllAvail"
                    @submit="submitRent"
                    @toMarketplace="toMarketplace"
                    @deleteItem="deleteRentItem"
                    @getOrders="getOrders"
                    @expandToggle="expandToggle"
                  />
                </get-data>
                <get-data
                  v-if="activeTab === 'computing'"
                  :fetch-function="getCarts"
                  :infinity="true"
                  :data-preloader-props="{ isSpinner: true }"
                >
                  <order-cart
                    :is-loading="rentLoading"
                    :orders="handleOrders"
                    :total="cartTotal.total_price || 0"
                    :total-vat="cartTotal.total_vat || 0"
                    :total-items="cartTotal.total_count || 0"
                    :expanded="expanded"
                    :cart-items="cart.items"
                    :all-avail="true"
                    @submit="submitWh"
                    @toMarketplace="toComputing"
                    @deleteCartItem="deleteCartItem"
                    @getOrders="getCarts"
                    @expandToggle="expandToggle"
                  />
                </get-data>
                <div class="sidebar-outer--closer" @click="close">
                  <svgicon :name="'cross-red'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <ConfirmationContainer></ConfirmationContainer>
      <TFAContainer></TFAContainer>
      <ServerErrorModal
        :modal-opened="serverErrorModalOpened"
        @toggle="togleModal"
      ></ServerErrorModal>
      <app-modal v-model="isWhModalOpened" :max-width="700">
        <template #header>
          <div class="modal-box text-center">
            <h3 class="text-headline1">
              {{ $gettext("Before proceed") }}
            </h3>
          </div>
        </template>
        <template #default>
          <p style="line-height: 1.7">
            Service Agreement Terms By proceeding with the purchase of this service, the customer
            agrees to the following: 1. The customer grants InterLIR GmbH permission to share their
            personal data and information with WannaHost Inc. (19901, 8 The Green, Ste R, City of
            Dover, County of Kent), for the purpose of completing the service transaction. 2. The
            customer understands that the service purchase will be processed by WannaHost Inc. 3.
            The customer agrees to the Terms of Service of WannaHost Inc., available at
            <a target="_blank" href="https://wannahost.com/terms">https://wannahost.com/terms.</a>
          </p>

          <div class="buttons" style="margin-top: 20px">
            <app-button
              type="external-link"
              variant="primary"
              size="normal"
              :href="whOrderLink"
              :prevent-click="false"
              target="_blank"
            >
              {{ $gettext("Agree and make an order") }}
            </app-button>
          </div>
        </template>
      </app-modal>
    </template>
  </div>
</template>
<script>
import AppCopy from "@/components/shared/Copy"
import AppSidebar from "@/components/shared/Sidebar"
import AppHeader from "@/components/shared/Header"
import AlertContainer from "@/components/shared/Alert"
import GetData from "@/components/shared/GetData"
import { GET_ORGANIZATIONS } from "@/store/organizations/types"
import withCurrentOrganization from "@/mixins/withCurrentOrganization"
import intercom from "@/mixins/intercom"
import TopLoading from "@/components/shared/TopLoading"
import ConfirmationContainer from "../components/shared/Modals/ConfirmationContainer"
import TFAContainer from "@/components/shared/Modals/TFAContainer.vue"
import RentList from "@/components/shared/RentList/RentList.vue"
import ServerErrorModal from "@/components/shared/Modals/ServerErrorModal"
import AppTabs from "@/components/ui/AppTabs.vue"
import OrderCart from "@/components/shared/OrderCart/OrderCart.vue"
import AppModal from "@/components/ui/AppModal.vue"
import AppButton from "@/components/ui/AppButton.vue"
import whIntegration from "@/mixins/whIntegration"

export default {
  name: "MainLayout",
  components: {
    AppButton,
    AppModal,
    OrderCart,
    AppTabs,
    RentList,
    TFAContainer,
    AppSidebar,
    AppHeader,
    AlertContainer,
    AppCopy,
    GetData,
    TopLoading,
    ConfirmationContainer,
    ServerErrorModal,
  },
  mixins: [withCurrentOrganization, intercom, whIntegration],
  data() {
    return {
      key: 0,
      isSidebarOpened: false,
      intercom: null,
      orders: Object,
      total: Object,
      rentLoading: false,
      expanded: false,
      isAllAvail: true,
      activeTab: "networks",
      isWhModalOpened: false,
    }
  },
  computed: {
    whOrderLink() {
      const accessToken = localStorage.getItem("wh_access_token")
      const refreshToken = localStorage.getItem("wh_refresh_token")
      const redirectTo = "/user/orders/new"
      return `${process.env.VUE_APP_WANNAHOST_DOMAIN}/force-login?access_token=${accessToken}&refresh_token=${refreshToken}&redirect_to=${redirectTo}`
    },
    whOpened() {
      return this.$store.state.wannahost.wh_open
    },
    tabs() {
      if (this.whOpened) {
        return [
          {
            name: "networks",
            label: `${this.$t("Networks")}(${this.orders.total | 0})`,
          },
          {
            name: "computing",
            label: `${this.$t("Computing")}(${this.cart.total | 0})`,
          },
        ]
      }
      return [
        {
          name: "networks",
          label: `${this.$t("Networks")}(${this.orders.total | 0})`,
        },
      ]
    },
    showSubstrate() {
      return this.isSidebarOpened || this.$store.getters["core/rentListOpened"]
    },
    rentListOpened() {
      return this.$store.getters["core/rentListOpened"]
    },
    rentList() {
      return this.$store.getters["marketplace/networksOrder"]
    },
    handleOrders() {
      if (!this.orders || !this.orders.items) {
        return []
      }
      const MAX_NETWORKS_PER_GROUP = 20
      const groupedOrders = []
      const getLastIndexOfSupplier = (groupedOrders, supplier) => {
        for (let i = groupedOrders.length - 1; i >= 0; i--) {
          if (groupedOrders[i].supplier === supplier) {
            return i
          }
        }
        return -1
      }
      this.orders.items.forEach((order) => {
        const existingGroupIndex = getLastIndexOfSupplier(groupedOrders, order.supplier)
        if (
          existingGroupIndex === -1 ||
          groupedOrders[existingGroupIndex].networks_data.length >= MAX_NETWORKS_PER_GROUP
        ) {
          groupedOrders.push({
            supplier: order.supplier,
            networks_data: [order],
          })
        } else {
          groupedOrders[existingGroupIndex].networks_data.push(order)
        }
      })
      return groupedOrders
    },
    cartTotal() {
      return this.$store.state.wh_cart.userCartTotal
    },
    cart() {
      return this.$store.state.wh_cart.userCart
    },
    serverErrorModalOpened() {
      return this.$store.state.core.serverErrorModal.opened
    },
  },
  watch: {
    $route() {
      this.checkWhOpen()
      this.closeSubstrate()
    },
    async currentOrganization(newValue, oldValue) {
      await this.fetchOrderItems()
      if (oldValue !== null) {
        console.log("we are fetching oldValue", oldValue)
        this.key++
      }
    },
    async rentList() {
      await this.getOrders()
      this.getTotals()
    },
    async rentListOpened(value) {
      if (value === true) {
        this.$intercom.hide()
        this.$intercom.update({
          hide_default_launcher: true,
        })
        return document.body.classList.add("overflowed")
      }
      this.$intercom.update({
        hide_default_launcher: false,
      })
      document.body.classList.remove("overflowed")
    },
  },
  async mounted() {
    await this.checkWhOpen()
    await this.fetchWhCarts()
    console.log(this.whOpened)
  },
  methods: {
    async submitRent() {
      await this.getOrders()
      if (this.isAllAvail) {
        await this.$store.dispatch("core/closeRentList")
        await this.$router.replace({ name: "confirmPayment" })
      } else {
        this.$alert({
          message:
            "One or several of selected networks are unavailable. Please, delete them from the cart.",
          type: "error",
        })
      }
    },
    async togleModal() {
      return await this.$store.dispatch("core/toggleServerErrorModal", true)
    },
    async toMarketplace() {
      await this.$store.dispatch("core/closeRentList")
      await this.$router.replace({ name: "marketplace" })
    },
    closeSubstrate() {
      this.isSidebarOpened = false
      this.$store.dispatch("core/closeRentList")
    },
    onSidebarToggle(isOpened) {
      this.isSidebarOpened = isOpened
    },
    async getOrganizations() {
      console.log("get organisations")
      try {
        await this.$store.dispatch(`organizations/${GET_ORGANIZATIONS}`)
      } catch (e) {
        console.warn("Get organizations error", e)
      }
    },
    async fetchOrderItems() {
      if (this.currentOrganization && this.currentOrganization.unique_id) {
        await this.$store.dispatch("marketplace/fetchOrderItems", {
          organisation_uid: this.currentOrganization.unique_id,
        })
      }
      return []
    },
    async getTotals() {
      const request = {
        inetnums: this.rentList,
        organization_uid: this.currentOrganization.unique_id,
      }
      try {
        this.total = await this.$store.dispatch("marketplace/orderSum", request)
      } catch (e) {
        this.$alert({
          message: this.$gettext(this.$utils.getServerValidationErrors(e)),
          type: "info",
        })
      }
    },
    async getOrders() {
      this.rentLoading = true
      if (this.rentList.length === 0) {
        this.orders = []
        this.rentLoading = false
        return
      }
      const request = {
        inetnums: this.rentList,
        organization_uid: this.currentOrganization.unique_id,
        params: { size: this.expanded ? 9999 : 20 },
      }
      try {
        this.orders = await this.$store.dispatch("marketplace/orderList", request)
        this.isAllAvail = this.orders.items.every((item) => item.available)
      } catch (e) {
        this.orders = []
        await this.$store.dispatch("marketplace/clearOrder", {
          organisation_uid: this.currentOrganization.unique_id,
        })
        this.$alert({
          message: this.$gettext(this.$utils.getServerValidationErrors(e)),
          type: "info",
        })
      } finally {
        this.rentLoading = false
      }
    },
    async expandToggle() {
      this.expanded = !this.expanded
      if (this.activeTab === "networks") {
        return await this.getOrders()
      }
      await this.getCarts()
    },
    async deleteRentItem(network) {
      this.rentLoading = true
      await this.$store.dispatch("marketplace/removeNetworkFromOrder", {
        network: network,
        organization_uid: this.currentOrganization.unique_id,
      })
      this.rentLoading = false
    },
    async close() {
      await this.$store.dispatch("core/closeRentList")
    },
    async getCarts() {
      if (!this.whGuest) {
        this.rentLoading = true
        const pageSize = this.expanded ? 999 : 10
        await this.$store.dispatch("wh_cart/updatePageSize", pageSize)
        await this.$store.dispatch("wh_cart/fetchCart")
        await this.$store.dispatch("wh_cart/fetchTotal")
        this.rentLoading = false
      }
    },
    async submitWh() {
      await this.close()
      this.isWhModalOpened = true
    },
    async toComputing() {
      await this.$store.dispatch("core/closeRentList")
      await this.$router.replace({ name: "ComputingIndex" })
    },
    async deleteCartItem(cartItem) {
      this.rentLoading = true
      await this.$store.dispatch("wh_cart/deleteItem", {
        uid: cartItem.uid,
      })
      this.rentLoading = false
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings.scss";

.application {
  display: flex;
  background: var(--background-color);

  &__inner {
    flex: 1 1 auto;
    display: flex;
    backface-visibility: hidden;
    flex-direction: column;
    max-width: 100%;
    min-height: 100vh;
    position: relative;
  }

  &__container {
    position: relative;
    overflow-x: hidden;
  }
}

.content-outer {
  overflow: hidden;
  padding-bottom: 25px;
  flex-basis: 100%;
  flex-grow: 1;
  max-width: 100%;
}

.content-stretch {
  padding: 32px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 auto;
}

.sidebar-outer {
  position: absolute;
  transform: translateX(-100%);
  top: 60px;
  height: calc(100% - 60px);
  transition: var(--transition-fast);
  z-index: 11;
  visibility: hidden;

  &--active {
    transform: translateX(0);
    visibility: visible;
  }

  &--header {
    background: var(--text-color);
    color: #fff;
    letter-spacing: 0.4px;
    font-weight: 100;
    text-transform: uppercase;
  }

  &--right {
    transform: translateX(100%) !important;
    right: 0 !important;

    &--active {
      transform: translateX(0) !important;
      visibility: visible;
    }

    @media (max-width: #{$--mobile-max-width}) {
      display: none;
    }
  }

  &--wrapper {
    position: relative;
  }

  &--closer {
    background: var(--text-color);
    font-size: 16px;
    color: var(--icons-color);
    width: 45px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
  }
}

.main-layout {
  position: relative;
}

.substrate {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
}
</style>
