import api_wh from "@/store/wannahost/api_wh"
import functions from "@/utils/functions"
import uuid from "uuid"
import { len } from "vuelidate/lib/validators/common"
const getters = {
  /**
   * Enable TFA
   * @param app
   * @param data
   * @returns {Array<Object<name: str, url: str, description: str | undefined>>}
   */
  getCategories(app, data) {
    return [
      {
        name: "VPN",
        url: "/net-services/vpn",
        description:
          "VPN (Virtual Private Network) is a secure connection that encrypts your internet traffic and routes",
        icon: "vpn",
        slug: "vpn",
      },
      {
        name: "Proxy",
        url: "/net-services/proxy",
        icon: "proxy",
        slug: "proxy",
        description:
          "A proxy is an intermediary server that acts as a gateway between a user and the internet",
      },
      {
        name: "Virtual Servers",
        url: "/computing/vps",
        icon: "vps",
        slug: "vps",
        description:
          "VPS/VDS are virtualized servers that mimic a dedicated server within a shared hosting environment",
      },
      {
        name: "GPU Dedicated Server",
        url: "/computing/gpu",
        icon: "gpu",
        slug: "gpu",
        description:
          "These servers are equipped with high-performance Graphics Processing Units (GPUs",
      },
      {
        name: "Dedicated Server",
        url: "/computing/dedicated-server",
        icon: "dedicated",
        slug: "dedicated",
        description:
          "A dedicated server is a physical server exclusively leased or owned by a client",
      },
    ]
  },
  getFilters() {
    return {
      list_numeric: "select",
      range_numeric: "select",
      country: "country",
      location: "select",
    }
  },
  excludeFilters() {
    return [
      "Note",
      "Provider",
      "Data center",
      "OS",
      "Preinstalled software",
      "delivery ETA(minutes)",
      "delivery ETA",
      "CPU",
      "Storage type",
      "DDoS protection",
    ]
  },
}
const actions = {
  auth: async ({ commit, dispatch }) => {
    const token = localStorage.getItem("auth_token_default").split(";")
    const identity = len(token) > 1 ? token[1] : token[0]
    const params = {
      identity: "interlir",
      identity_token: identity,
    }
    const response = await api_wh.post(
      process.env.VUE_APP_API_WANNAHOST + "/integrations/auth",
      params
    )
    const { access_token, refresh_token } = response.data
    dispatch("setWhAuth", {
      access_token,
      refresh_token,
    })
  },
  refresh: async ({ dispatch }) => {
    const response = await api_wh.post(process.env.VUE_APP_API_WANNAHOST + "/users/access/refres")
    const { access_token, refresh_token } = response.data
    dispatch("setWhAuth", {
      access_token,
      refresh_token,
    })
  },
  setWhAuth: ({ commit }, { access_token, refresh_token }) => {
    const uuid = require("uuid")
    localStorage.setItem("wh_access_token", access_token)
    localStorage.setItem("wh_refresh_token", refresh_token)
    localStorage.setItem("wh_device_uid", uuid.v4())
    commit("setWhGuest", false)
  },
  setWhGuest: ({ commit }) => {
    commit("setWhGuest", true)
  },
  logout({ commit, dispatch }) {
    localStorage.removeItem("wh_access_token")
    localStorage.removeItem("wh_refresh_token")
    localStorage.removeItem("wh_device_uid")
    commit("setWhGuest", true)
  },
  openWh({ commit }, { value }) {
    commit("setWhOpen", value)
  },
}
const mutations = {
  setWhGuest(state, value) {
    state.wh_guest = value
    console.log(state.wh_guest, "wh_guest")
  },
  setWhOpen(state, value) {
    state.wh_open = value
    console.log(state.wh_open, "wh_open")
  },
}
const state = {
  wh_guest: localStorage.getItem("wh_access_token") === null,
  wh_open: false,
}
export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations,
}
