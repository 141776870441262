export const ORGANIZATIONS = "organizations"
export const SET_ORGANIZATIONS = "setOrganizations"
export const SET_ORGANIZATION = "setOrganization"
export const GET_ORGANIZATIONS = "getOrganizations"
export const ORGANIZATION = "organization"
export const ORGANIZATION_ID = "organization_id"
export const ACCESS_LEVEL = "access_level"
export const NO_ORGANIZATION = "no_organization"
export const CUSTOMER = "customer"
export const STRIPE_CUSTOM = "stripe_custom"
export const STRIPE_STANDARD = "stripe_standard"
