var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.organizations)?_c('div',{staticClass:"selector"},[(_vm.organizations.length)?_c('app-dropdown',{attrs:{"content-styles":"min-width: 100%","offset":8},on:{"toggle":_vm.onDropdownToggle},scopedSlots:_vm._u([{key:"default",fn:function({ isOpened }){return [_c('div',{staticClass:"selector__current-container"},[_c('div',{staticClass:"selector__current-icon-holder"},[_c('svgicon',{attrs:{"name":"business"}})],1),_c('div',{staticClass:"selector__current text-body1",class:isOpened && 'selector__current--opened'},[(_vm.currentLogo)?_c('div',{staticClass:"selector__current-icon-holder"},[_c('img',{staticClass:"header-logo",attrs:{"src":_vm.currentLogo}})]):_vm._e(),_c('div',{staticClass:"selector__current-name"},[(_vm.currentOrganization && _vm.currentOrganization.name)?_c('span',[_vm._v(" "+_vm._s(_vm.currentOrganization.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("Select business account"))+" ")])]),_c('div',{staticClass:"selector__current-icon"},[_c('svgicon',{attrs:{"name":"arrow_drop_down"}})],1)])])]}},{key:"content",fn:function({ close }){return [_c('div',{staticClass:"selector__inner"},[_c('div',{staticClass:"selector-list"},[_c('div',{staticClass:"selector-list__title text-headline6"},[_vm._v(" "+_vm._s(_vm.$t("Business accounts"))+" "),_c('span',{staticClass:"selector-list__title-counter text-body2 text-color-secondary"},[_vm._v(" ("+_vm._s(_vm.organizations.length)+") ")])]),_c('ul',{staticClass:"selector-list__items"},_vm._l((_vm.sortedOrganizations),function(organization){return _c('li',{key:organization.unique_id,staticClass:"selector-list__item selector-item",class:{
                'selector-item--offset-right': !organization.is_owner,
                'selector-item--selected': _vm.selectedId === organization.unique_id,
                'selector-item--active': _vm.isOrganizationActive(organization),
              },on:{"click":function($event){return _vm.preselectOrganization(organization)}}},[_c('div',{staticClass:"selector-item__header"},[(_vm.selectLogo(organization.access_level))?_c('div',{staticClass:"selector__current-icon-holder"},[_c('img',{staticClass:"header-logo",attrs:{"src":_vm.selectLogo(organization.access_level)}})]):_c('div',{staticClass:"selector__current-icon-holder"},[_c('span',{staticClass:"header-shift"})]),_c('div',{staticClass:"selector-item__name text-subtitle2"},[_vm._v(" "+_vm._s(organization.name)+" ")]),(organization.is_owner)?_c('div',{staticClass:"selector-item__is-owner"},[_c('app-tooltip',{attrs:{"position-x":"left"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("You owner"))+" ")]},proxy:true}],null,true)},[_c('div',{staticClass:"selector-item__is-owner-icon"},[_c('svgicon',{attrs:{"name":"default_avatar"}})],1)])],1):_vm._e()]),_c('div',{staticClass:"selector-item__actions"},[_c('div',{staticClass:"selector-item__action"},[_c('app-button',{attrs:{"variant":"primary","size":"tiny"},on:{"click":function($event){return _vm.select(organization, close)}}},[_vm._v(" "+_vm._s(_vm.$t("Select"))+" ")])],1),_c('div',{staticClass:"selector-item__action"},[_c('app-button',{attrs:{"type":"link","to":{
                      name: 'editOrganization',
                      params: { id: organization.unique_id },
                    },"variant":"outlined","size":"tiny"}},[_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")])],1)])])}),0)]),_c('div',{staticClass:"selector-create",on:{"click":close}},[_c('router-link',{staticClass:"selector-create__button button-clear text-body2",attrs:{"to":{ name: 'createOrganization' }}},[_c('svgicon',{attrs:{"name":"add"}}),_vm._v(" "+_vm._s(_vm.$t("Add new business account"))+" ")],1)],1)])]}}],null,false,1885088854)}):_c('create-organization-link',{staticClass:"selector__create-new"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }