<template>
  <div class="notifications">
    <app-dropdown>
      <template #default="{ isOpened }">
        <div class="notifications__trigger-container">
          <div
            class="notifications__trigger"
            :class="[isOpened && 'notifications__trigger--active']"
          >
            <app-badge :count="unreadNotifications">
              <svgicon name="bell" />
            </app-badge>
          </div>
        </div>
      </template>
      <template #content>
        <div class="notifications-list">
          <get-data :fetch-function="getNotifications">
            <div class="notifications-list__inner">
              <div class="notifications-list__header text-headline3">
                {{ $t("Notifications") }}
              </div>
              <div v-prevent-background-scroll class="notifications-list__body">
                <perfect-scrollbar
                  v-if="rawNotificationList.length"
                  class="notifications-list__scrollbar"
                >
                  <div
                    v-for="(notifications, groupName) in groupedNotifications"
                    :key="groupName"
                    class="notifications-list__group"
                  >
                    <div class="notifications-list__group-title text-body2 text-color-secondary">
                      {{ groupName }}
                    </div>
                    <notification-card
                      v-for="notification in notifications"
                      :id="notification.unique_id"
                      :key="notification.unique_id"
                      :title="notification.title"
                      :text="notification.text"
                      :date="notification.dt_create"
                      :viewed="notification.viewed"
                      :username="notification.sender_name"
                      class="notifications-list__card"
                      @read="setReadStatus"
                      @unread="setUnreadStatus"
                      @remove="removeNotification"
                    />
                  </div>
                </perfect-scrollbar>
                <div
                  v-else
                  class="text-body1 text-color-secondary notifications-list__empty-message"
                >
                  {{ $t("You have no new notifications") }}
                </div>
              </div>
            </div>
          </get-data>
        </div>
      </template>
    </app-dropdown>
  </div>
</template>

<script>
import { debounce } from "lodash"
import GetData from "@/components/shared/GetData"
import AppBadge from "@/components/ui/Badge"
import AppDropdown from "@/components/ui/Dropdown"
import NotificationCard from "./NotificationCard"
import {
  DELETE_NOTIFICATION,
  SET_UNREAD_NOTIFICATION,
  NOTIFICATIONS,
  GROUPED_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  UNREAD_NOTIFICATION_COUNT,
  SET_READ_NOTIFICATIONS,
} from "@/store/notifications/types"

export default {
  components: { AppBadge, AppDropdown, GetData, NotificationCard },
  computed: {
    groupedNotifications() {
      return this.$store.getters[`notifications/${GROUPED_NOTIFICATIONS}`]
    },
    rawNotificationList() {
      return this.$store.state["notifications"][NOTIFICATIONS]
    },
    unreadNotifications() {
      return this.$store.state["notifications"][UNREAD_NOTIFICATION_COUNT]
    },
    newEvent() {
      return this.$store.state.user.sseMessageNotification
    },
  },
  created() {
    this.loadNotifications()
  },
  methods: {
    async loadNotifications() {
      await this.$store.dispatch(`notifications/${GET_NOTIFICATIONS}`)
    },
    async getNotifications() {
      await this.$store.dispatch(`notifications/${GET_NOTIFICATIONS}`)
      if (this.rawNotificationList.length) {
        this.$store.dispatch(`notifications/${SET_READ_NOTIFICATIONS}`, {
          ids: this.rawNotificationList.filter((nt) => !nt.viewed).map((nt) => nt.unique_id),
        })
      }
    },
    setReadStatus(id) {
      this.$store.dispatch(`notifications/${SET_READ_NOTIFICATIONS}`, {
        ids: [id],
      })
    },
    setUnreadStatus(id) {
      this.$store.dispatch(`notifications/${SET_UNREAD_NOTIFICATION}`, { id })
    },
    removeNotification(id) {
      this.$store.dispatch(`notifications/${DELETE_NOTIFICATION}`, { id })
    },
  },
  watch: {
    newEvent: debounce(async function () {
      await this.loadNotifications()
    }, 4000),
  },
}
</script>

<style scoped lang="scss">
.notifications {
  &__trigger-container {
    height: 40px;
    display: flex;
    align-items: center;
  }
  &__trigger {
    color: var(--text-white);
    font-size: 24px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: var(--transition-fast);
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: transparent;
      border-radius: 50%;
      transition: var(--transition-fast);
    }
    &--active {
      color: var(--color-red);
      &:before {
        background: rgba(238, 27, 75, 0.5);
      }
    }
  }
}
.notifications-list {
  padding: 20px 0 0 13px;
  width: 350px;
  @media (max-width: 568px) {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
    z-index: 9;
  }
  &__header {
    color: #000;
    padding: 0px 9px 11px 3px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--border-color);
  }
  &__body {
    overflow: hidden;
    max-height: 400px;
    display: flex;
    flex-direction: column;
  }
  &__scrollbar {
    padding-right: 16px;
    height: 100%;
  }
  &__card {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__group {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &-title {
      padding-left: 3px;
      margin-bottom: 8px;
    }
  }
  &__empty-message {
    text-align: center;
    padding: 15px 0;
  }
}
</style>
