<template>
  <div v-if="organizations" class="selector">
    <app-dropdown
      v-if="organizations.length"
      content-styles="min-width: 100%"
      :offset="8"
      @toggle="onDropdownToggle"
    >
      <template #default="{ isOpened }">
        <div class="selector__current-container">
          <div class="selector__current-icon-holder">
            <svgicon name="business" />
          </div>
          <div
            class="selector__current text-body1"
            :class="isOpened && 'selector__current--opened'"
          >
            <div v-if="currentLogo" class="selector__current-icon-holder">
              <img class="header-logo" :src="currentLogo" />
            </div>
            <div class="selector__current-name">
              <span v-if="currentOrganization && currentOrganization.name">
                {{ currentOrganization.name }}
              </span>
              <span v-else>
                {{ $t("Select business account") }}
              </span>
            </div>
            <div class="selector__current-icon">
              <svgicon name="arrow_drop_down" />
            </div>
          </div>
        </div>
      </template>
      <template #content="{ close }">
        <div class="selector__inner">
          <div class="selector-list">
            <div class="selector-list__title text-headline6">
              {{ $t("Business accounts") }}
              <span class="selector-list__title-counter text-body2 text-color-secondary">
                ({{ organizations.length }})
              </span>
            </div>
            <ul class="selector-list__items">
              <li
                v-for="organization in sortedOrganizations"
                :key="organization.unique_id"
                class="selector-list__item selector-item"
                :class="{
                  'selector-item--offset-right': !organization.is_owner,
                  'selector-item--selected': selectedId === organization.unique_id,
                  'selector-item--active': isOrganizationActive(organization),
                }"
                @click="preselectOrganization(organization)"
              >
                <div class="selector-item__header">
                  <div
                    v-if="selectLogo(organization.access_level)"
                    class="selector__current-icon-holder"
                  >
                    <img class="header-logo" :src="selectLogo(organization.access_level)" />
                  </div>
                  <div v-else class="selector__current-icon-holder">
                    <span class="header-shift"></span>
                  </div>
                  <div class="selector-item__name text-subtitle2">
                    {{ organization.name }}
                  </div>
                  <div v-if="organization.is_owner" class="selector-item__is-owner">
                    <app-tooltip position-x="left">
                      <div class="selector-item__is-owner-icon">
                        <svgicon name="default_avatar" />
                      </div>
                      <template #content>
                        {{ $t("You owner") }}
                      </template>
                    </app-tooltip>
                  </div>
                </div>
                <div class="selector-item__actions">
                  <div class="selector-item__action">
                    <app-button variant="primary" size="tiny" @click="select(organization, close)">
                      {{ $t("Select") }}
                    </app-button>
                  </div>
                  <div class="selector-item__action">
                    <app-button
                      type="link"
                      :to="{
                        name: 'editOrganization',
                        params: { id: organization.unique_id },
                      }"
                      variant="outlined"
                      size="tiny"
                    >
                      {{ $t("Edit") }}
                    </app-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="selector-create" @click="close">
            <router-link
              :to="{ name: 'createOrganization' }"
              class="selector-create__button button-clear text-body2"
            >
              <svgicon name="add" />
              {{ $t("Add new business account") }}
            </router-link>
          </div>
        </div>
      </template>
    </app-dropdown>
    <create-organization-link v-else class="selector__create-new" />
  </div>
</template>

<script>
import AppDropdown from "@/components/ui/Dropdown"
import AppTooltip from "@/components/ui/Tooltip"
import AppButton from "@/components/ui/AppButton"
import CreateOrganizationLink from "./CreateOrganizationLink"
import {
  ORGANIZATIONS,
  ORGANIZATION,
  SET_ORGANIZATION,
  STRIPE_STANDARD,
  STRIPE_CUSTOM,
} from "@/store/organizations/types"
import interlir_logo from "@/assets/img/interlir_logo.png"
import stripe_logo from "@/assets/img/stripe_logo.png"

export default {
  components: { AppDropdown, AppTooltip, AppButton, CreateOrganizationLink },
  data() {
    return {
      selectedId: null,
    }
  },
  computed: {
    currentOrganization() {
      return this.$store.getters[`organizations/${ORGANIZATION}`]
    },
    organizations() {
      return this.$store.state.organizations[ORGANIZATIONS]
    },
    sortedOrganizations() {
      if (!this.currentOrganization) {
        return this.organizations
      }
      const currentOrganizationId = this.currentOrganization.unique_id
      return this.organizations.slice().sort((a, b) => {
        return a.unique_id === currentOrganizationId
          ? -1
          : b.unique_id === currentOrganizationId
          ? 1
          : 0
      })
    },
    currentLogo() {
      if (!this.currentOrganization) {
        return interlir_logo
      }
      return this.selectLogo(this.currentOrganization.access_level)
    },
  },
  methods: {
    isOrganizationActive(organization) {
      if (!this.currentOrganization || !this.currentOrganization.unique_id) {
        return false
      }
      return this.currentOrganization.unique_id === organization.unique_id
    },
    preselectOrganization(organization) {
      this.selectedId = organization.unique_id
    },
    onDropdownToggle() {
      this.selectedId = null
    },
    async select(organization, closeDropdownFn) {
      if (organization && organization.unique_id) {
        await this.$store.dispatch(`organizations/${SET_ORGANIZATION}`, organization.unique_id)
        closeDropdownFn && closeDropdownFn()
      }
    },
    selectLogo(accessLevel) {
      if (accessLevel === STRIPE_STANDARD) {
        return stripe_logo
      } else if (accessLevel === STRIPE_CUSTOM) {
        return interlir_logo
      }
      return
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";
.selector {
  &__current {
    display: flex;
    padding: 2px 3px 2px 11px;
    border-radius: 4px;
    align-items: center;
    color: var(--text-white);
    &-container {
      height: 40px;
      display: flex;
      align-items: center;
    }
    &--opened {
      .selector__current-icon {
        transform: rotate(180deg);
      }
    }
    &-icon-holder {
      font-size: 20px;
      color: var(--icons-color);
    }
    &-icon {
      transition: var(--transition-fast);
      color: rgba(255, 255, 255, 0.3);
      font-size: 26px;
      margin-left: 5px;
      display: flex;
    }
    &-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
    }
  }
  &__inner {
    width: 242px;
  }
}
.selector-list {
  &__title {
    padding: 13px 16px;
  }
  &__items {
    border-top: 0.5px solid var(--border-color);
    border-bottom: 0.5px solid var(--border-color);
    padding: 4px 0;
  }
}
.selector-item {
  padding: 0 10px 0 16px;
  cursor: pointer;
  &--offset-right {
    padding-right: 16px;
  }
  &__header {
    display: flex;
    align-items: center;
    height: 33px;
  }
  &__name {
    flex: 1 1 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--text-grey);
    transition: var(--transition-fast);
    line-height: 16px;
  }
  &__is-owner {
    &-icon {
      font-size: 22px;
      color: var(--primary-dark-color);
      opacity: 0.3;
      display: flex;
    }
  }
  &:hover,
  &--active,
  &--selected {
    .selector-item__name {
      color: var(--text-color);
    }
  }
  &__actions {
    display: none;
  }
  &--selected {
    background: rgba(229, 229, 229, 0.4);
    .selector-item__actions {
      display: flex;
      padding-bottom: 6px;
    }
  }
  &__action {
    &:first-child {
      margin-right: 15px;
    }
  }
}
.selector-create {
  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 13px;
    white-space: nowrap;
  }
}
.header-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.header-shift {
  margin: 0px 40px 0px 0px;
}
@media (max-width: #{$--mobile-max-width}) {
  .selector {
    &__create-new {
      font-size: 11px;
      padding: 8px 4px;
    }
    &__current-icon-holder {
      display: none;
    }
    &__current-name {
      max-width: 150px;
    }
  }
}
</style>
