import Vue from "vue"
import * as configcat from "configcat-js"
const plugin = {}

plugin.install = function () {
  const configcatClient = function (options) {
    const logger = configcat.createConsoleLogger(configcat.LogLevel.Info)
    let configCatClient = null
    let integration = undefined
    const init = function () {
      configCatClient = configcat.getClient(
        "configcat-sdk-1/mtzcCDnIOkGCiyLpPRQbYQ/5BVLIy9PGUayPD7A0mRHXQ",
        configcat.PollingMode.AutoPoll,
        {
          logger: logger,
        }
      )
      return this
    }
    const loadIntegration = async function (id, userEmail, integrationId) {
      init()
      const userObject = new configcat.User(id, userEmail)
      this.integration = await configCatClient.getValueAsync(integrationId, false, userObject)
    }
    return {
      client: configCatClient,
      loadIntegration,
      integration,
    }
  }
  Vue.configcatClient = configcatClient()
  Vue.prototype.$configcatClient = configcatClient()
}
Vue.use(plugin)

export default plugin
