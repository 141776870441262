<template>
  <div class="button-rents-container">
    <app-dropdown :dropdown="true">
      <template #default="{ isOpened }">
        <div
          class="button-rents-container__holder-buttons"
          :class="{
            'button-rents-container__holder-buttons--opened': isOpened,
          }"
        >
          <span class="button-rents-container__holder-buttons-button-title">
            <svgicon :name="buttonIcon" class="icon" />
            <span class="title">
              {{ buttonTitle }}
            </span>
            <span v-if="totalCount > 0" class="button-rents-container__badge">
              <app-badge :count="totalCount"> </app-badge>
            </span>
          </span>
          <span class="button-rents-container__holder-buttons-button-icon">
            <svgicon name="arrow_drop_down" />
          </span>
        </div>
      </template>
      <template #content="{ close }">
        <ul class="button-rents-container__holder-content">
          <li>
            <a
              @click="
                openRentList()
                close()
              "
              ><span class="flex-box align-content-center"
                >{{ $t("Open my order") }}
                <app-badge :count="totalCount" class="app-badge-small"> </app-badge></span
            ></a>
          </li>
          <li>
            <a
              @click="
                close()
                $router.push({ name: 'marketplace' })
              "
              >{{ $t("Open marketplace") }}</a
            >
          </li>
        </ul>
      </template>
    </app-dropdown>
  </div>
</template>

<script>
import AppDropdown from "@/components/ui/Dropdown"
import AppBadge from "@/components/ui/Badge.vue"
import whIntegration from "@/mixins/whIntegration"

export default {
  name: "HeaderButtonRent",
  components: {
    AppBadge,
    AppDropdown,
  },
  mixins: [whIntegration],
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      required: true,
    },
    subLinks: {
      type: Array,
      required: true,
    },
    rootLink: {
      type: [String, Object],
      default: null,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalCount() {
      const userCartTotal = !this.whGuest ? this.$store.state.wh_cart.userCart.total : 0
      const totalNetworkOrder = this.$store.getters["marketplace/networksOrder"]
      return totalNetworkOrder.length + userCartTotal
    },
  },
  methods: {
    openRentList(e) {
      this.$store.dispatch("core/openRentList")
    },
  },
}
</script>

<style scoped lang="scss">
.button-rents-container {
  display: flex;
  &__holder-buttons {
    color: var(--text-white);
    background-color: rgba(46, 46, 99, 1);
    width: 180px;
    padding: 17px 0px;
    justify-content: center;
    display: flex;
    align-items: center;

    &--opened {
      .button-rents-container__holder-buttons-button-icon {
        .svg-icon {
          transform: rotate(180deg);
        }
      }
    }
    &-button-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-button-title {
      .icon {
        color: var(--icons-color);
        font-size: 20px;
        margin-right: 5px;
      }
    }

    &-button-icon {
      .svg-icon {
        transition: var(--transition-fast);
        color: rgba(255, 255, 255, 0.3);
        font-size: 26px;
        margin-left: 5px;
      }
    }
  }
  &__badge {
    width: 16px;
    height: 16px;
    display: block;
    margin: 0 3px;
  }
  &__holder-content {
    background: var(--text-white);
    display: block;
    width: 200px;

    li {
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom-color: transparent;
      }

      a {
        padding: 10px 13px;
        display: block;
        border: none;
        color: var(--text-grey);
        font-family: var(--font-primary);
        font-size: 12px;
        cursor: pointer;

        &:hover {
          border: none;
          color: var(--text-color);
        }
      }
    }
  }
}
.app-badge-small {
  margin-left: 20px;
}
</style>
