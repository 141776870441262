<template>
  <div class="order-cart-item">
    <div class="order-cart-item--wrapper">
      <div class="order-cart-item--header">
        <div class="order-cart-item--header-title">
          {{ cartItem.offer.product.name_translations.en | clear_name }}
          <div class="order-cart-item--header-supplier">
            {{ cartItem.offer.name }}
          </div>
        </div>
        <div class="order-cart-item--header-count">
          {{ cartItem.count }}
        </div>
        <div class="order-cart-item--computing-price">
          {{ cartItem.offer.price | price }}
        </div>
        <div class="order-cart-item--computing-remove" @click="$emit('delete', cartItem)">
          <svgicon :name="'cross-red'" />
        </div>
      </div>
      <div v-for="configuration in cartItem.configurations" :key="configuration.uid">
        <div class="order-cart-item--computing">
          <div class="order-cart-item--computing-name">
            <country-flag
              v-if="getCountry(configuration)"
              :country="getCountry(configuration)"
              size="small"
            />
            <span class="order-cart-item--computing-title">
              {{ getTitle(configuration) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag"

export default {
  components: { CountryFlag },
  props: {
    cartItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: [],
    }
  },
  methods: {
    getCountry(configuration) {
      const country = Object.keys(configuration.configuration).find((item) => {
        return configuration.configuration[item].product_option_label.en === "Country"
      })
      return configuration.configuration[country].value
    },
    getTitle(configuration) {
      const coreParams = ["RAM", "CPU", "Core", "GPU", "Storage"]
      const coreParamsValues = Object.keys(configuration.configuration)
        .filter((item) => {
          return coreParams.includes(configuration.configuration[item].product_option_label.en)
        })
        .map((item) => {
          const label = configuration.configuration[item].product_option_label.en
          const value = configuration.configuration[item].value
          const unit = this.cartItem.offer.catalog_offer_items.find((itemProductOption) => {
            return (
              itemProductOption.product_option.uid ===
              configuration.configuration[item].product_option_uid
            )
          })
          return unit.product_option && unit.product_option.unit
            ? `${label}: ${value} ${unit.product_option.unit}`
            : `${label}: ${value}`
        })
      return coreParamsValues.join(", ")
    },
  },
}
</script>

<style scoped lang="scss">
.order-cart-item {
  font-family: var(--font-secondary);
  color: var(--sidebar-color);
  background: #252546;

  &--header {
    background: #2e2e63;
    font-family: var(--font-secondary);
    display: flex;
    padding: 12px 15px;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    letter-spacing: 1px;

    &-title {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.3;
      max-width: 150px;
      font-weight: bold;
      .order-cart-item--header-supplier {
        font-size: 10px;
        text-transform: none;
        font-weight: normal;
      }
    }
  }

  &--computing {
    font-size: 13px;
    display: flex;
    padding: 15px 10px;
    justify-content: space-between;
    align-items: center;

    &-price {
      color: var(--sidebar-active-color);
    }

    &-remove {
      color: #9090d9;
      cursor: pointer;
    }

    &-network {
      display: flex;
      align-items: center;

      &-unavail {
        color: grey;
        font-size: 10px;
      }

      &-alert {
        color: red;
      }
    }
  }
}

.order-cart-item--computing-title {
  font-size: 11px;
  line-height: 1.1;
}

.order-cart-item--computing-name {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.order-cart-item--computing-title {
  margin-left: 15px;
}
</style>
