import Vue from "vue"

import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js"
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js"
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js"
import driverOAuth2Google from "@websanova/vue-auth/dist/drivers/oauth2/google.esm.js"
import driverOAuth2Facebook from "@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js"
import { ACCESS_LEVEL, ORGANIZATION_ID } from "@/store/organizations/types"

const tokenDefaultName = "access_token"

Vue.use(auth, {
  tokenStore: ["localStorage"],
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: {
      request: function (req, token) {
        try {
          token = token.split(";")[0]
          const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/")
          const tokenExpire = JSON.parse(Buffer.from(base64, "base64").toString("utf-8")).exp
          if (tokenExpire * 1000 >= Date.now()) {
            req.headers["Authorization"] = "Bearer " + token
            req.headers["Organization-Uid"] = localStorage.getItem(ORGANIZATION_ID)
            return
          }
          throw Error("Auth request error")
        } catch (error) {
          localStorage.removeItem(ACCESS_LEVEL)
          this.logout()
        }
      },
      response: function (res) {
        if (!res.data) {
          return
        }
        if (res.data[tokenDefaultName]) {
          return res.data[tokenDefaultName] + ";" + res.data[tokenDefaultName]
        }
      },
    },
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
      facebook: driverOAuth2Facebook,
    },
  },

  options: {
    parseUserData: (data) => {
      return data
    },
    loginData: {
      url: "/auth/jwt/login",
      fetchUser: true,
      staySignedIn: true,
    },
    fetchData: {
      url: "/users/me",
      method: "GET",
      enabled: false,
    },
    refreshData: {
      url: "/auth/jwt/refresh",
      method: "POST",
      enabled: true,
    },
    authRedirect(from) {
      sessionStorage.setItem("redirect_to", from.fullPath)
      return "/login"
    },
  },
})
