import Vue from "vue"
import i18n from "./i18n"
import index from "./utils/functions"

Vue.filter("stringToHumanBoolean", (str) => {
  const mapper = {
    1: "Yes",
    0: "No",
    true: "Yes",
    false: "No",
  }
  return mapper[str]
})

Vue.filter("monthSuffix", (str) => {
  return str + " " + i18n.tc("month | months", parseInt(str, 10))
})
Vue.filter("dateFormat", (str) => {
  return index.formatDate(str)
})

Vue.filter("price", (str) => {
  return index.formatCurrency(str)
})
Vue.filter("snakeToHuman", (str) => {
  const words = str.match(/[A-Za-z][a-z]*/g) || []

  return words
    .map((word, index) => {
      return index === 0 ? word.charAt(0).toUpperCase() + word.substring(1) : word
    })
    .join(" ")
})

Vue.filter("capitalise", (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
})

Vue.filter("monthList", (array) => {
  return array.join(", ")
})

Vue.filter("clear_name", (str) => {
  return str.replace("Virtual Servers", "").replace("(", "").replace(")", "")
})
