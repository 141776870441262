<template>
  <div class="tabs">
    <div class="tabs__header">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        class="tabs__header-item"
        :class="{ 'tabs__header-item--active': activeTab === tab.name }"
        @click="setTab(tab.name)"
      >
        {{ tab.label }}
      </div>
    </div>
    <div v-if="contentTabs" class="tabs__content">
      <slot :name="activeTab" />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "activeTab",
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    contentTabs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    setTab(tabName) {
      this.$emit("input", tabName)
    },
  },
}
</script>

<style scoped lang="scss">
.tabs {
  &__header {
    display: flex;
    position: relative;
    &:after {
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      background: var(--border-color);
      z-index: -1;
    }
    &-item {
      border-bottom: 4px solid transparent;
      color: var(--text-secondary);
      cursor: pointer;
      padding: 12px 18px;
      font-weight: 600;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &--active {
        cursor: default;
        border-bottom-color: var(--primary-color);
        color: var(--text-primary);
      }
    }
  }
  &__content {
    padding-top: 27px;
  }
}
</style>
