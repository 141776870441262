/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vps': {
    width: 40,
    height: 44,
    viewBox: '0 0 512 512',
    data: '<g _fill="#000000">\n' +
        '\t<path class="st0" d="M152.773,51.613c-17.102,0-30.967,13.863-30.967,30.969c0,17.102,13.865,30.965,30.967,30.965\n' +
        '\t\tc17.104,0,30.969-13.863,30.969-30.965C183.742,65.476,169.877,51.613,152.773,51.613z"/>\n' +
        '    <rect x="307.613" y="61.936" class="st0" width="41.289" height="41.291"/>\n' +
        '    <rect x="369.547" y="61.936" class="st0" width="41.293" height="41.291"/>\n' +
        '    <path class="st0" d="M152.773,275.613c-17.102,0-30.967,13.863-30.967,30.969c0,17.102,13.865,30.965,30.967,30.965\n' +
        '\t\tc17.104,0,30.969-13.863,30.969-30.965C183.742,289.476,169.877,275.613,152.773,275.613z"/>\n' +
        '    <rect x="307.613" y="285.936" class="st0" width="41.289" height="41.291"/>\n' +
        '    <rect x="369.547" y="285.936" class="st0" width="41.293" height="41.291"/>\n' +
        '    <path class="st0" d="M433.953,224H276.645v-48h-41.289v48H78.047c-30.277,0-54.82,24.545-54.82,54.82v110.342h210.656l1.472,0.002\n' +
        '\t\tv45.982c-6.195,3.606-11.312,8.723-14.918,14.918H133.08l-0.711-0.002v0.002h-0.24v41.291h88.309\n' +
        '\t\tC227.59,503.644,240.754,512,256,512c15.244,0,28.41-8.356,35.562-20.644h88.309v-41.291h-88.309\n' +
        '\t\tc-3.606-6.195-8.723-11.312-14.918-14.918v-45.984h210.18l1.709,0.002v-0.002h0.24V278.82C488.773,248.545,464.231,224,433.953,224\n' +
        '\t\tz M55.227,278.82c0-12.584,10.236-22.82,22.82-22.82h355.906c12.584,0,22.82,10.236,22.82,22.82v78.342H55.227V278.82z"/>\n' +
        '    <path class="st0" d="M433.953,0H78.047c-30.277,0-54.82,24.545-54.82,54.82V160H224h11.355h41.289H304h184.773V54.82\n' +
        '\t\tC488.773,24.545,464.231,0,433.953,0z M55.227,54.82c0-12.584,10.236-22.82,22.82-22.82h355.906c12.584,0,22.82,10.236,22.82,22.82\n' +
        '\t\tV128H55.227V54.82z"/>\n' +
        '</g>'
  }
})
