<template>
  <aside class="sidebar">
    <div class="sidebar__logo">
      <app-logo theme="light" />
    </div>
    <nav class="sidebar-navigation">
      <ul>
        <SidebarItem
          v-for="link in items"
          :key="link.label"
          :to="link.to"
          :disabled="link.disabled"
          :icon="link.icon"
          :label="link.label"
          :nested-links="link.items"
        />
        <div v-if="integration" class="sidebar-navigation__delimiter small" />
        <SidebarItem
          v-if="integration"
          :to="computingMenu.to"
          :icon="computingMenu.icon"
          :label="computingMenu.label"
          :nested-links="computingMenu.items"
        />
        <div v-if="integration" class="sidebar-navigation__delimiter small" />
        <div v-if="!integration" class="sidebar-navigation__delimiter" />
        <SidebarItem to="/settings" icon="settings" :label="$t('Settings')" />
      </ul>
    </nav>
  </aside>
</template>

<script>
import SidebarItem from "./SidebarItem"
import AppLogo from "@/components/shared/Logo"
import { EXCLUSIVE_PATHS, FORBIDDEN_PATHS, ALTERED_PATHS } from "@/utils/routers"
import { ACCESS_LEVEL } from "@/store/organizations/types"

export default {
  components: { SidebarItem, AppLogo },
  computed: {
    rawItems() {
      let menu = [
        {
          label: this.$t("Dashboard"),
          to: "/dashboard",
          icon: "dashboard",
        },
        {
          label: this.$t("Marketplace"),
          icon: "store",
          items: [
            {
              label: this.$t("Rent"),
              to: "/marketplace",
            },
            {
              label: this.$t("For sale"),
              to: "/sales-offers",
            },
            {
              label: this.$t("Auctions"),
              to: "/marketplace-sales",
            },
          ],
        },
        {
          label: this.$t("My resources"),
          icon: "business_center",
          items: [
            {
              label: this.$t("IP-blocks"),
              to: "/inetnums",
            },
            {
              label: this.$t("My reserves"),
              to: "/reserves",
            },
            {
              label: this.$t("Leased IP-blocks"),
              to: "/contracts",
            },
            {
              label: this.$t("For sale"),
              to: "/my-sales-offers",
            },
            {
              label: this.$t("ASN"),
              to: "/asn",
            },
            {
              label: this.$t("Assignments"),
              to: "/assignments",
            },
            {
              label: this.$t("Reverse DNS"),
              to: "/reverse-dns-request",
            },
            {
              label: this.$t("Supplier's contracts"),
              to: "/contracts-lease-out",
            },
            {
              label: this.$t("Abuse complaints"),
              to: "/abused_blocks",
            },
            // {
            //   label: this.$t("Utilization"),
            //   to: "/utilization",
            // },
          ],
        },
        {
          label: this.$t("Auction"),
          icon: "auction",
          items: [
            {
              label: this.$t("My auctions"),
              to: "/network-sales",
            },
            {
              label: this.$t("My bids"),
              to: "/network-sales-bids",
            },
          ],
        },
        {
          label: this.$t("Billing"),
          icon: "payment",
          items: [
            {
              label: this.$t("Invoices"),
              to: "/invoices",
            },
            {
              label: this.$t("Payment methods"),
              to: "/billing",
            },
            {
              label: this.$t("Payouts"),
              to: "/payouts-requests",
            },
            {
              label: this.$t("Payout methods"),
              to: "/payouts-methods",
            },
            {
              label: this.$t("Reports"),
              to: "/reports",
            },
          ],
        },
        {
          label: this.$t("Offers"),
          icon: "paid",
          items: [
            {
              label: this.$t("My"),
              to: "/my-offers",
            },
            {
              label: this.$t("Incoming"),
              to: "/received-offers",
            },
          ],
        },
        {
          label: this.$t("Support"),
          icon: "help",
          to: "/support",
        },
        {
          label: this.$t("Knowledge base"),
          to: "/knowledge-base",
          icon: "school",
        },
      ]
      return menu
    },
    // integration() {
    //   return this.$configcatClient.integration
    // },
    integration() {
      return this.$store.state.wannahost.wh_open
    },
    // { name: item.name, params: item.params }
    computingMenu() {
      const integrationsItems = this.$store.getters["wannahost/getCategories"].map((item) => {
        const categoryProduct = item.url.split("/")
        return {
          label: item.name,
          to: {
            name: "ComputingPage",
            params: {
              category: categoryProduct[1],
              product: categoryProduct[2],
            },
          },
        }
      })
      return {
        label: this.$t("Computing"),
        icon: "select_all",
        items: integrationsItems,
      }
    },
    items() {
      const res = this.rawItems.map((item) => {
        if (item.items) {
          item.items = item.items.map((nestedItem) => {
            nestedItem.disabled = this.checkAccessLevel(nestedItem.to)
            nestedItem.to = this.checkAlteredPath(nestedItem.to)
            return nestedItem
          })
        }
        item.disabled = this.checkAccessLevel(item.to)
        item.to = this.checkAlteredPath(item.to)
        return item
      })
      return res
    },
  },
  methods: {
    checkAccessLevel(route) {
      if (!route) return false
      const currentAccessLevel = localStorage.getItem(ACCESS_LEVEL)
      const alteredRoutes = ALTERED_PATHS[currentAccessLevel]
      if (alteredRoutes && alteredRoutes.hasOwnProperty(route)) {
        return false
      }
      for (const [accessLevel, restrictedRoutes] of Object.entries(EXCLUSIVE_PATHS)) {
        if (
          accessLevel !== currentAccessLevel &&
          restrictedRoutes.some((restrictedRoute) => route.includes(restrictedRoute))
        ) {
          return true
        }
      }
      const restrictedRoutes = FORBIDDEN_PATHS[currentAccessLevel]
      if (
        restrictedRoutes &&
        restrictedRoutes.some((restrictedRoute) => route.includes(restrictedRoute))
      ) {
        return true
      }
      return false
    },
    checkAlteredPath(route) {
      if (!route) return
      const currentAccessLevel = localStorage.getItem(ACCESS_LEVEL)
      const alteredRoutes = ALTERED_PATHS[currentAccessLevel]
      if (alteredRoutes && alteredRoutes.hasOwnProperty(route)) {
        return alteredRoutes[route]
      }
      return route
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";
.sidebar {
  background: var(--text-color);
  height: 100%;
  padding: 24px 8px;
  width: $--sidebar-width;
  overflow: auto;
  &__logo {
    margin-bottom: 59px;
    display: flex;
    padding-left: 20px;
    @media (min-width: #{$--mobile-max-width}) {
      display: none;
    }
  }
}
.sidebar-navigation {
  &__delimiter {
    width: 100%;
    height: 1px;
    background: var(--border-color);
    margin-top: 15px;
    margin-bottom: 40px;
    opacity: 0.3;
    &.small {
      margin: 15px 0px;
    }
  }
}
</style>
