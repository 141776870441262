<template>
  <div>
    <app-modal
      :is-opened="modalOpened"
      :max-width="650"
      :full-height="false"
      @toggle="$emit('toggle')"
    >
      <slot name="header">
        <h3 class="text-headline1 text-center">{{ $t("We'll Be Right Back!") }}</h3>
      </slot>
      <div class="center-container">
        <div class="text-headline3 flex-center-mobile">
          {{ $t("Our portal is currently down for scheduled maintenance.") }}
        </div>
        <div class="text-headline3 flex-center-mobile">
          {{ $t(longText) }}
        </div>
        <div class="text-center">
          <app-button size="large" @click="reload()">
            {{ $t("Refresh page") }}
          </app-button>
        </div>
      </div>
    </app-modal>
  </div>
</template>

<script>
import AppModal from "@/components/ui/AppModal"
import AppButton from "@/components/ui/AppButton"

export default {
  components: { AppModal, AppButton },
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      opened: false,
      longText:
        "We are working hard to improve your experience and will be back online shortly. Thank you for your patience!",
    }
  },
  methods: {
    reload() {
      window.location.reload()
    },
  },
}
</script>

<style scoped lang="scss">
.center-container > * {
  margin: 15px 0;
}
</style>
