import api_wh from "@/store/wannahost/api_wh"

const baseApi = process.env.VUE_APP_API_WANNAHOST

export default {
  namespaced: true,
  state: {
    userCart: {
      total: 0,
      page: 0,
      size: 10,
      pages: 0,
      items: [],
    },
    userCartTotal: {
      total_price: 0,
      total_count: 0,
      total_vat: 0,
      payment_reference: null,
    },
    pageSize: 10,
  },
  getters: {
    hasNextPage: (state) => {
      return state.userCart.page < state.userCart.pages
    },
  },
  mutations: {
    SET_CART(state, cart) {
      state.userCart = cart
    },
    ADD_TO_CART_ITEMS(state, items) {
      state.userCart.items = state.userCart.items.concat(items)
    },
    SET_CART_TOTAL(state, cartTotal) {
      state.userCartTotal = cartTotal
    },
    UPDATE_PAGE_SIZE(state, pageSize) {
      state.pageSize = pageSize
    },
  },
  actions: {
    async fetchCart({ commit, state }) {
      const response = await api_wh.get(`/cart?size=${state.pageSize}`)
      commit("SET_CART", response.data)
    },
    async loadMore({ commit, state }, page) {
      const response = await api_wh.get(`/cart?size=${state.pageSize}&page=${page}`)
      const { items } = response.data
      commit("ADD_TO_CART_ITEMS", items)
    },
    async addToCart({ dispatch, commit, state }, data) {
      const response = await api_wh.post(`/cart?size=${state.pageSize}`, data)
      commit("SET_CART", response.data)
      await dispatch("fetchTotal")
    },
    async clearCart({ dispatch, commit, state }, data) {
      const response = await api_wh.delete(`/cart?size=${state.pageSize}`, {
        data,
      })
      commit("SET_CART", response.data)
      await dispatch("fetchTotal")
    },
    async deleteItem({ dispatch, commit, state }, data) {
      const response = await api_wh.delete(`/cart/${data.uid}?size=${state.pageSize}`)
      commit("SET_CART", response.data)
      await dispatch("fetchTotal")
    },
    async fetchTotal({ commit, state }) {
      const response = await api_wh.get(`/cart/summary?size=${state.pageSize}`)
      commit("SET_CART_TOTAL", response.data)
    },
    async updateCount({ dispatch, commit, state }, { uid, count }) {
      const response = await api_wh.patch(`/cart/${uid}?size=${state.pageSize}`, {
        count,
      })
      commit("SET_CART", response.data)
      await dispatch("fetchTotal")
    },
    async updatePageSize({ commit, state }, pageSize) {
      console.log(pageSize, "pageSize")
      commit("UPDATE_PAGE_SIZE", pageSize)
    },
  },
}
